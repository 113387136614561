

const development = {
    url: {
        API_URL: "http://dev.gr8floors.com:5001",
        API_STYLES_BY_NAME: 'http://dev.gr8floors.com:5001/api/pricing',
        // API_STYLES_BY_NAME_COLOR: 'http://dev.gr8floors.com:5001/api/:stylename/:color'
        // API_STYLES_BY_NAME_COLOR: 'http://dev.gr8floors.com:5001/api/:stylename/:color'
    }
}

const production = {
    url: {
        API_URL: "http://dev.gr8floors.com:5001",
        API_STYLES_BY_NAME: 'http://dev.gr8floors.com:5001/api/pricing',
        API_STYLES_BY_NAME_COLOR: 'http://dev.gr8floors.com:5001/api/:stylename/:color'
    }
}

export const Constants = process.env.NODE_ENV === 'development' ? development : production;
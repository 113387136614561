import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Constants } from '../utilities/Constants'
import StyleLogoIntro from '../components/StyleLogoIntro'
import Modal from '../shared/Modal'

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }

const SelectedStyles = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [styles, setStyles] = useState([])
  const [introDetails, setIntroDetails] = useState('')
  const [modalData, setModalData] = useState('')


  // const { name } = useParams()
  const { style_name } = useParams()
  // console.log(typeof name.toString())
  // let name = "allstar"


  useEffect(() => {
    async function fetchData() {
      const controller = new AbortController();

      // axios.get(`${Constants.url.API_STYLES_BY_NAME}/${para.toString().toLowerCase()}`).then(
      axios.get(`${Constants.url.API_STYLES_BY_NAME}/${style_name.toString().toLowerCase()}`).then(
        (result) => {
          // console.log(result.data.rows[0].sell_price)
          console.log(result.data)
          // console.log(result.data.rows[0])
          setIntroDetails(result.data.rows[0])
          setStyles(result.data.rows)
        },
        
      );
      return () => {
        controller.abort();
      };
    }
    fetchData();
  }, [style_name]);



  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  return (
    <div>
      {/* {window.location.pathname === '/' ? <><h1>"Welcome home"</h1><Link to='/pricing/route2'>Page 2</Link></> :  */}
    <>
      {/* <StyleLogoIntro
        manufacturer={'DIXIE'}  
        styleName={introDetails.style_name}
        price={Number(introDetails.sell_price).toFixed(2)}
      /> */}

      <ul className='mt-3'>
        {styles && styles.map((style) => (
          <li key={style.color_name} style={{ paddingLeft: "20px", paddingBottom: "10px" }}>
            <button onClick={() => { setModalData(style); setIsOpen(true); console.log(style) }} >{titleCase(style.color_name)}</button>
          </li>
        ))}
      </ul>

      <Modal open={isOpen} onClose={() => setIsOpen(false)} styleName={modalData.color_name} />
    </>
{/* } */}
    </div>
  )
}

export default SelectedStyles
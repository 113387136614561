import React from 'react'

const Footer = () => {
  return (
    <div className='flex justify-center' style={{backgroundColor: "#0060a9", position: "absolute", bottom: "0", width: "100%"}}>
      <div style={{color: "white", padding: "15px"}}></div>
    </div>
  )
}

export default Footer
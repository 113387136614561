import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import {  useParams } from 'react-router'
import { Constants } from '../utilities/Constants'

const SelectedColors = () => {
    let navigate = useNavigate()
    // const { name } = 'allstar'
    const [data, setData] = useState([])
    const { para } = useParams()
    // const { para } = 'landmark'
    console.log("Before use effect " + typeof para)

    useEffect(() => {
        async function fetchData() {
            console.log(para.toString())
            axios.get(`${Constants.url.API_STYLES_BY_NAME}/${para.toString().toLowerCase()}`).then(
                
                (result) => {
                    console.log("in axios " + typeof para)
                    console.log(result.data.rows)
                    setData(result.data.rows)
                },
            );
        }
        fetchData();
    }, []);

    // console.log(para)

    const back = () => {
        navigate('/')
    }
    return (
        <div>
            <h1>This route works, and the param is {para}</h1>
            <div>
                <h3>The data is</h3>

                {data.map((da) => (
                    <>
                        <p>{da.color_name}</p>
                    </>
                ))}

            </div>
            <button onClick={back}>Go Back</button>
        </div>
    )
}

export default SelectedColors
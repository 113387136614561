import { Route, Routes } from "react-router-dom";
import Header from "./shared/Header";
import SelectedColors from "./pages/SelectedColors";
import SelectedStyles from "./pages/SelectedStyles";
import Footer from "./shared/Footer";
import HomePage from "./pages/HomePage";


function App() {
  return (
    <div >
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/:style_name' element={<SelectedStyles />} />
        {/* <Route path='/:name' element={<SelectedStyles />} /> */}
        <Route path='/route2/:para' element={<SelectedColors />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

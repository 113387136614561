import React from 'react'
// import { Link } from 'react-router-dom'

const HomePage = () => {
  return (
    <div>
        HomePage
        {/* <Link to='/route2' >Go to route 2</Link>
        <Link to='/allstar' >Go to allstar</Link>
        <Link to='/benchmark' >Go to benchmark</Link> */}
    </div>
  )
}

export default HomePage
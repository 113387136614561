import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import  ReactDOM  from 'react-dom'


export default function Modal({ open, isOpen, onClose, styleName }) {
    if(!open) return null;

    return ReactDOM.createPortal(
        <>
         <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10"  onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  bg-opacity-75 transition-opacity" style={{backgroundColor: "#0060a9"}} />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto pb-28">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className='flex justify-center'>
                   
                   </div>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                
                  <div className="sm:flex sm:items-center">
                  
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                   
                      <div >
                          Image goes here
                      </div>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        
                        {styleName}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Description goes here {styleName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClose}
                    // ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
        </>,
        document.getElementById('portal')
    )
}
import React from 'react'

const Header = () => {
  return (
    <div className='flex justify-center' style={{backgroundColor: "#0060a9"}}>
      <div style={{color: "white", padding: "15px"}}>Great Floors</div>
    </div>
  )
}

export default Header